<!--
 * @Author: your name
 * @Date: 2022-02-17 09:40:17
 * @LastEditTime: 2022-02-17 09:40:17
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \ZZ-payrolltax\src\views\Wisdom\PeopleCenter\Order.vue
-->
<template>
  <div class="Order">
    <div>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="commodity_info" v-for="item in 10" :key="item">
          <div class="Redeem_list_box">
            <div>
              <van-image
                class="Redeem_list_box_img"
                fit="contain"
                lazy-load
                :src="require('@/assets/img/peopleCenter_order_01.jpg')"
              >
              </van-image>
            </div>
            <div class="Redeem_list_box_right">
              <h3 class="van-multi-ellipsis--l2">
                中智薪税2022年2月专题课-劳务合同
              </h3>
              <div class="notes">
                <span>2022/2/11</span>
                <span>v2等级领取</span>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <copyrightIp />
  </div>
</template>
<script>
import { List, Cell, Image as VanImagen } from "vant";
import copyrightIp from "@/components/copyrightIp";
export default {
  components: {
    [VanImagen.name]: VanImagen,
    [List.name]: List,
    [Cell.name]: Cell,
    copyrightIp,
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
    };
  },
  methods: {
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }

        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
.Order {
  width: 100%;

  .commodity_info {
    width: 100%;
    padding: 15px;
    margin-bottom: 10px;
    background: #fff;

    .Redeem_list_box {
      width: 100%;
      display: flex;
      padding-top: 5px;
      .Redeem_list_box_img {
        width: 100px;
        border-radius: 8px;
        overflow: hidden;
      }
      .Redeem_list_box_right {
        display: flex;
        flex-wrap: wrap;
        padding-left: 5px;
        h3 {
          font-size: 15px;
          font-weight: bold;
          padding-bottom: 10px;
        }
        .notes {
          width: 100%;
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          color: #a3a2a2;
        }
      }
    }
  }
}
</style>
